// Abstracts
@use "abstracts/index" as *;

.metadata-fields {
    margin-top: box-model(m);

    h4 {
        margin-bottom: box-model(xs);
        font-size: 0.875rem;
    }

    .metadata-category-list {
        .expandable-content-header {
            display: flex;
            gap: box-model(xs);

            .drag-handle {
                display: flex;
                align-items: center;
                padding: 0;
            }
        }
    }

    .metadata-fields-category {
        border: 1px solid color(base-300);
        border-radius: $large-border-radius;
        padding: box-model(m);
        margin: box-model(m, 0);

        .activator > button {
            i {
                @include icon-size(xs);
                display: inline;
            }
        }

        .metadata-category-without-fields {
            padding: box-model(l);
            text-align: center;
            grid-column: 1 / 6;
        }
    }

    .metadata-category-fields {
        display: grid;
        grid-template-columns: 2fr 10fr 10fr 10fr 7fr 1fr;
        border-radius: $large-border-radius;
        border: 1px solid color(base-300);

        .metadata-fields-list, header, .metadata-field-form {
            display: grid;
            grid-template-columns: subgrid;
            grid-column-start: 1;
            grid-column-end: span 6;
        }

        header {
            background-color: var(--base-300);
            font-weight: bold;
            border-radius: box-model(xs, xs, 0, 0);
        }

        .metadata-field-form {
            &:nth-child(even) {
                background-color: var(--base-050);

                select:disabled, textarea:disabled {
                    border-color: var(--base-300);
                }
            }
            &:last-child {
                border-radius: box-model(0, 0, xs, xs);
            }
        }

        .metadata-cell {
            grid-column-end: span 1;
            padding: box-model(s);

            & > .form-control,
            & > .form-group,
            & > .read-only-field-name,
            & > .metadata-edit-choice-list {
                margin-bottom: box-model(xs);
            }

            > i {
                margin-left: box-model(s);
            }
        }

        .drag-handle {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            grid-column-start: 1;
        }

        .field-name{
            grid-column-start: 2;
            .read-only-field-name {
                padding: box-model(xxs, s);

                i {
                    margin-left: box-model(xxs);
                }
            }
        }

        .field-kind {
            grid-column-start: 3;
        }

        .label-tooltip {
            grid-column-start: 4;
        }

        .default-value {
            grid-column-start: 5;
        }

        .actions {
            grid-column-start: 6;
            button {
                i {
                    margin: 0;
                }
            }
        }
    }

    .category-to-delete {
        .expandable-content-header {
            opacity: 0.3;
        }
    }

    .metadata-field-form.to-delete {
        .metadata-cell {
            opacity: 0.3;
        }

        .metadata-cell.actions {
            opacity: 1;
        }
    }

    .is-focused {
        .metadata-cell {
            animation-duration: 1.5s;
            animation-name: backgroundOpacity;
        }
    }

    @keyframes backgroundOpacity {
        from {
            background-color: rgba(79, 90, 99, 1);
        }
        to {
            background-color: rgba(79, 90, 99, 0);
        }
    }

    .secondary-page-toolbar {
        .dropdown {
            margin-right: box-model(xs);
        }
    }

    .drag-handle {
        i {
            @include font-size(document);
        }
    }
}
